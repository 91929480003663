import { getAboutBook, getOneBook } from "api";
import { GET_ABOUT_BOOK, GET_ONE_BOOK, LOADING } from "./constants";

export const fetchOneBook = (book) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const {data} = await getOneBook(book);
    dispatch({ type: GET_ONE_BOOK, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const fetchUnfollowedSessions= (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const {data} = await getOneBook(id);
    dispatch({ type: GET_ONE_BOOK, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const getAboutBookAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAboutBook(id);
    
    dispatch({ type: GET_ABOUT_BOOK, payload: data.about });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_ABOUT_BOOK, payload: null });
    dispatch({ type: LOADING, payload: false });
  }
};
