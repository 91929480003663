// import node module libraries
import React, { Fragment, useEffect } from "react";
import {
  Col,
  Row,
  Container,
  Nav,
  Card,
  Tab,
  ListGroup,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

// import popup youtube video

// import custom components
import GKAccordionDefault from "components/marketing/common/accordions/GKAccordionDefault";
import Ratings from "components/marketing/common/ratings/Ratings";

// import sub components
import CourseCard from "../CourseCard";

// import sub components tabs

import DescriptionTab from "./DescriptionTab";

import FAQTab from "./FAQTab";

// import data files
import { CourseIndex } from "data/marketing/CourseIndexData";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchAllPackDetailsAction,
  fetchBooksInPackAction,
} from "actions/packs";
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";
//translate component
import { useTranslation } from "react-i18next";

const CourseSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pack, books } = useSelector((state) => state.packs);
  const { packs } = useSelector((state) => state.packs);
  const { followed_pack_list, user } = useSelector((state) => state.auth);

  // const [isOpen, setOpen] = useState(false);
  const packIsFllowed = followed_pack_list?.filter((item) => item.id == id);

  useEffect(() => {
    if (id) dispatch(fetchAllPackDetailsAction(id));
    dispatch(fetchBooksInPackAction(id));
  }, [dispatch, id]);
  const { t } = useTranslation();
  const whatsIncludeddData = {
    hourly: t("hours"),
    cert: t("certificate"),
    book_nbr: t("books"),
    access: t("year_access"),
    winner: t("the_best_reader"),
  };

  const tabs = ["content", "description", "faq"];

  return (
    <Fragment>
      {/* Page header */}
      <section className="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
        <Container>
          <Row className="align-items-center">
            <Col xl={7} lg={7} md={12} sm={12}>
              <div>
                <h1 className="text-white display-4 fw-semi-bold">
                  {pack?.title}
                </h1>
                {/* <p className="text-white mb-6 lead">{pack?.desc}</p> */}
                <div className="d-flex align-items-center">
                  {/* <GKTippy content="Add to Bookmarks">
                    <Link
                      to="#"
                      className="bookmark text-white text-decoration-none"
                    >
                      <i className="fe fe-bookmark text-white-50 me-2"></i>
                      Bookmark
                    </Link>
                  </GKTippy> */}
                  <span className="text-white ms-3">
                    <i className="fe fe-user text-white-50"></i>{" "}
                    {pack?.enrolled} {t("enrolled")}{" "}
                  </span>
                  <span className="ms-4">
                    <span className="text-warning">
                      <Ratings rating={4.5} />
                      {/* <span className="text-white ms-1">(140)</span> */}
                    </span>
                  </span>
                  <span className="text-white ms-4 d-none d-md-block">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="3"
                        y="8"
                        width="2"
                        height="6"
                        rx="1"
                        fill="#DBD8E9"
                      ></rect>
                      <rect
                        x="7"
                        y="5"
                        width="2"
                        height="9"
                        rx="1"
                        fill="#DBD8E9"
                      ></rect>
                      <rect
                        x="11"
                        y="2"
                        width="2"
                        height="12"
                        rx="1"
                        fill="#DBD8E9"
                      ></rect>
                    </svg>{" "}
                    <span className="align-middle">
                      <LevelIcon level={"Advance"} />
                      {pack?.level}
                    </span>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Page content */}
      <section className="pb-10">
        <Container>
          <Row>
            <Col lg={8} md={12} sm={12} className="mt-n8 mb-4 mb-lg-0">
              <Tab.Container defaultActiveKey="content">
                <Card>
                  <Nav className="nav-lb-tab">
                    {tabs.map((item, index) => (
                      <Nav.Item key={index}>
                        <Nav.Link
                          href={`#${item.toLowerCase()}`}
                          eventKey={item.toLowerCase()}
                          className="mb-sm-3 mb-md-0"
                        >
                          {t(item)}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Card.Body className="p-0">
                    <Tab.Content>
                      <Tab.Pane eventKey="content" className="pb-4 pt-3 px-4">
                        {/* Course Index Accordion */}
                        <GKAccordionDefault
                          accordionItems={CourseIndex}
                          itemClass="px-0"
                          books={books}
                          pack_id={id}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="description" className="pb-4 p-4">
                        {/* Description */}
                        <DescriptionTab pack={pack} />
                      </Tab.Pane>

                      <Tab.Pane eventKey="faq" className="pb-4 p-4">
                        {/* FAQ */}
                        <FAQTab faq={pack.faq} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Card.Body>
                </Card>
              </Tab.Container>
            </Col>
            <Col lg={4} md={12} sm={12} className="mt-lg-n22">
              {/* Card */}
              <Card className="mb-3 mb-4">
                <div>
                  <div
                    className="d-flex justify-content-center position-relative"
                    style={{ marginTop: "-40px" }}
                  >
                    <Link to="#">
                      {/* <i className="fe fe-play"></i> */}
                      <img
                        style={{
                          height: "250px",
                          width: "360px",
                          border: "4px solid white",
                          borderRadius: "12px",
                        }}
                        src={pack.img}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>

                {/* Card body */}
                <Card.Body>
                  {/* Price single page */}
                  <div className="mb-3">
                    <span className="text-dark fw-bold h2 me-2">
                      {pack?.price} Tnd
                    </span>
                    <del className="fs-4 text-muted">
                      {pack?.price + pack?.discount} Tnd
                    </del>
                  </div>

                  <div className="d-grid">
                    {packIsFllowed?.length === 0 && (
                      <Link
                        to={"/courses/course-checkout/" + id}
                        className="btn btn-primary mb-2"
                      >
                        {t("btn_follow_pack")}
                      </Link>
                    )}
                    {packIsFllowed?.length === 1 &&
                    !packIsFllowed[0]?.approved ? (
                      <Link className="btn btn-warning mb-2">
                        {t("pending_pack")}
                      </Link>
                    ) : (
                      packIsFllowed?.length === 1 && (
                        <Link className="btn btn-success mb-2">
                          {t("approved_pack")}
                        </Link>
                      )
                    )}
                  </div>
                </Card.Body>
              </Card>
              {/* Card */}
              <Card className="mb-4">
                {/* Card header */}
                <Card.Header>
                  <h4 className="mb-0">{t("what_includes")}</h4>
                </Card.Header>
                {/* Card Body */}
                <Card.Body className="p-0">
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <i className="fe fe-clock align-middle me-2 text-primary"></i>
                      {pack?.duration || whatsIncludeddData.hourly}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <i className="fe fe-award me-2 align-middle text-success"></i>
                      {whatsIncludeddData.cert}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <i className="fe fe-book align-middle me-2 text-info"></i>
                      {whatsIncludeddData.book_nbr}
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent">
                      <i className="fe fe-clock align-middle me-2 text-warning"></i>
                      {whatsIncludeddData.access}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <i className="fe fe-award align-middle me-2 text-warning"></i>
                      {whatsIncludeddData.winner}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* Card */}
          <div className="pt-12 pb-3">
            <Row className="d-md-flex align-items-center mb-4">
              <Col lg={12} md={12} sm={12}>
                <h2 className="mb-0">{t("related_packs")}</h2>
              </Col>
            </Row>
            <Row>
              <Row>
                {packs
                  ?.filter((item) => item.age === pack?.age && item.id != id)
                  .slice(0, 4)
                  .map((item, index) => (
                    <Col lg={3} md={6} sm={12} key={index}>
                      <CourseCard item={item} free />
                    </Col>
                  ))}
              </Row>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default CourseSingle;
