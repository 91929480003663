// import node module libraries
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Card, Form, Button, Image, Spinner } from "react-bootstrap";
import { signInAction, signInGoogleAction } from "actions/auth";
import { useDispatch, useSelector } from "react-redux";
// import media files
import Logo from "assets/images/brand/logo/logo.png";
import { ToastContainer } from "react-toastify";
//translate component
import { useTranslation } from "react-i18next";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";

// CUSTOMIZE ANY UI BUTTON
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

const SignIn = () => {
  const { t } = useTranslation();

  const { loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  // const handleGoogleLogin = (data) => {
  //   let { email, name } = jwtDecode(data);
  //   dispatch(signInGoogleAction({ email, username: name }, navigate));
  // };

  const handleLoginSocialMedia = (data) => {
    let { email, name } = data;

    dispatch(signInGoogleAction({ email, username: name }, navigate));
  };

  const handleLoginGoogle = (access_token) => {
    fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => response.json())
      .then((userData) => {
        const { email, name } = userData;
        // Now you have the email and name, you can use them as needed
        dispatch(signInGoogleAction({ email, username: name }, navigate));
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    dispatch(signInAction(user, navigate));
  };
  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={5} md={5} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-4">
                <Link to="/">
                  <Image
                    src={Logo}
                    style={{ maxWidth: "120px" }}
                    className="mb-4"
                    alt=""
                  />
                </Link>
                <h1 className="mb-1 fw-bold">{t("sign_in")}</h1>
                <span>
                  {t("dont_have_acc")}{" "}
                  <Link to="/authentication/sign-up" className="ms-1">
                    {t("sign_up")}
                  </Link>
                </span>
              </div>

              {/* Form */}
              <Form>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Username or email */}
                    <Form.Label> {t("email")} </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      placeholder={t("email_adress_here")}
                      required
                      value={user.email}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>{t("password")} </Form.Label>
                    <Form.Control
                      type="password"
                      id="password"
                      placeholder="**************"
                      required
                      value={user.password}
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Checkbox */}
                    <div className="d-md-flex justify-content-between align-items-center">
                      <Form.Group
                        className="mb-3 mb-md-0"
                        controlId="formBasicCheckbox"
                      >
                        {/* <Form.Check type="checkbox" label="Remember me" /> */}
                      </Form.Group>
                      {/* <Link to="/authentication/forget-password">
                        {t("forget_pass")}
                      </Link> */}
                    </div>
                  </Col>
                  <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    {/* Button */}
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading}
                      onClick={(e) => handleSignIn(e)}
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          className="me-2"
                          size="sm"
                        />
                      ) : (
                        t("sign_in")
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr className="my-4" />
              {/* <div
                className="mb-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ marginBottom: "10px" }}>{t("or")} </span>

                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    handleGoogleLogin(credentialResponse.credential);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  text="continue_with"
                />
              </div> */}
              <div
                className="mb-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ marginBottom: "10px" }}>{t("or")} </span>

                <LoginSocialFacebook
                  // isOnlyGetToken
                  appId="947023506901597"
                  // onLoginStart={onLoginStart}
                  onResolve={(res) => {
                    handleLoginSocialMedia(res.data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <FacebookLoginButton
                    iconSize={20}
                    style={{ fontSize: 16, height: 40 }}
                  />
                </LoginSocialFacebook>

                <LoginSocialGoogle
                  isOnlyGetToken
                  client_id="48688787863-9vtv1e1lmdkgeu5j9greud5rcjp1l5la.apps.googleusercontent.com"
                  onResolve={(res) => {
                    handleLoginGoogle(res.data.access_token);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <GoogleLoginButton
                    iconSize={20}
                    style={{ fontSize: 18, height: 40, marginTop: 20 }}
                  />
                </LoginSocialGoogle>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default SignIn;
