// import node module libraries
import { useEffect, useState } from "react";
import { Form, Link, useLocation } from "react-router-dom";

import {
  Card,
  Image,
  Modal,
  Button,
  ListGroup,
  Badge,
  Row,
  Col,
  FormSelect,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

// Import required data
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  changeEmailAction,
  changeImageActions,
  changePasswordAction,
  createAccountAction,
  getAccountsAction,
  getProfileActions,
  setProfileActions,
} from "actions/auth";
import CloudinaryUploadWidget from "components/marketing/account-settings/CloudinaryUploadWidget";
import { FlatPickr } from "components/elements/flat-pickr/FlatPickr";
import PasswordStrengthMeter from "components/elements/passwordstrength/PasswordStrengthMeter";

const CourseDescSettingCard = ({ item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    user,
    loading,
    profile: userProfile,
    accounts,
  } = useSelector((state) => state.auth);
  const [image, setImage] = useState(user?.img);
  const [newAccount, setNewAccont] = useState({
    username: "",
    password: "",
  });
  const [profile, setProfile] = useState({
    address_1: "",
    address_2: "",
    birth_day: "",
    country: "",
    first_name: "",
    last_name: "",
    phone: "",
    state: "",
  });
  useEffect(() => {
    if (user) {
      dispatch(getAccountsAction());
    }
  }, [dispatch, user]);

  const handleCreateAccount = (e) => {
    e.preventDefault();
    dispatch(createAccountAction(newAccount, setNewAccont));
  };
  const pathInfo = useLocation();
  const account = pathInfo.pathname.substring(21, 11);
  const countrylist = [
    { value: "empty", label: "Select country" },
    { value: "Tunisia", label: "Tunisia" },
  ];

  const statelist = [
    { value: "Gabes", label: "Gabes" },
    { value: "Sfax", label: "Sfax" },
    { value: "Tunis", label: "Tunis" },
  ];
  useEffect(() => {
    if (user) {
      setImage(user.img);
      dispatch(getProfileActions());
    }
  }, [user]);

  useEffect(() => {
    if (userProfile?.user_id)
      setProfile({
        address_1: userProfile.address_1,
        address_2: userProfile.address_2,
        birth_day: userProfile.birth_day,
        country: userProfile.country,
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
        phone: userProfile.phone,
        state: userProfile.state,
      });
  }, [userProfile]);

  const handleChangeProfile = (e) => {
    e.preventDefault();
    const newDate = new Date(profile.birth_day);
    newDate.setDate(newDate.getDate() + 1);
    dispatch(setProfileActions({ ...profile, birth_day: newDate }));
    dispatch(changeImageActions({ img: image }));
  };
  const [modalEmailShow, setModalEmailShow] = useState(false);
  const [modalPassWordShow, setModalPassWordShow] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [currentpassword, setCurrentPassword] = useState("");
  const [currentpassword2, setCurrentPassword2] = useState("");
  const [new_email, setNew_email] = useState();

  const handleChangeEmail = (e) => {
    e.preventDefault();
    dispatch(changeEmailAction({ new_email, password: currentpassword })).then(
      () => {
        setNew_email("");
        setCurrentPassword("");
        setModalEmailShow(false);
      }
    );
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    if (password === confirmpassword) {
      dispatch(
        changePasswordAction({
          old_password: currentpassword2,
          new_password: password,
        })
      ).then(() => {
        setCurrentPassword2("");
        setPassword("");
        setConfirmPassword("");
        setModalPassWordShow(false);
      });
    } else {
      toast.error("Password doesn't match", { autoClose: 1000 });
    }
  };

  return (
    <Card className="mb-4">
      {/*  Card body  */}
      <Card.Body className="p-6">
        <div className="d-md-flex mb-4">
          <div className="mb-3 mb-md-0">
            <Image
              src={item.icon}
              alt=""
              className=" bg-primary icon-shape icon-xxl rounded-circle"
            />
          </div>
          {/*  Content  */}
          <div className="ms-md-4">
            <h2 className="fw-bold mb-1">
              {t(item.title)}
              {/* <Badge bg="warning" className="ms-2">
								{item.badge}
							</Badge> */}
            </h2>
          </div>
        </div>
        <p className="mb-4 fs-4">{t(item.shortdescription)}</p>
        <div>
          <Button
            style={{ color: "#0EA5E9" }}
            href="#"
            bsPrefix="btn-link"
            onClick={() => setModalEmailShow(true)}
          >
            <u>{t("change_email")} </u>
          </Button>
        </div>
        <div>
          <Button
            style={{ color: "#0EA5E9" }}
            href="#"
            bsPrefix="btn-link"
            onClick={() => setModalPassWordShow(true)}
          >
            <u>{t("change_passewo")} </u>
          </Button>
        </div>
        {/* Email Modal */}
        <Modal
          show={modalEmailShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setModalEmailShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="d-md-flex my-2">
                <div className="mb-3 mb-md-0">
                  <Image
                    src={item.icon}
                    alt=""
                    className=" bg-primary icon-shape icon-xxl rounded-circle"
                  />
                </div>
                {/*  Content  */}
                <div className="ms-md-4">
                  <h2 className="fw-bold mb-1">
                    {t(item.title)}
                    {/* <Badge bg="warning" className="ms-2">
										{item.badge}
									</Badge> */}
                  </h2>
                  <p className="text-uppercase fs-6 fw-semi-bold mb-0">
                    <span className="text-dark">
                      {t(item.shortdescription)}
                    </span>{" "}
                    {/* <span className="ms-3">{item.lessons} Lessons</span>{' '}
									<span className="ms-3">{item.duration}</span> */}
                  </p>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="mb-0">{t("email_adress")}</h4>
            <p>
              {t("your_current_email")}{" "}
              <span className="text-success">{user?.email}</span>
            </p>
            <form>
              <Row>
                <Col lg={6} md={12} sm={12} className="mb-3">
                  <div className="mb-3">
                    <label htmlFor="email">{t("new_email_adress")}</label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      required
                      onChange={(e) => setNew_email(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="currentpassword">
                      {t("current_password")}
                    </label>
                    <input
                      type="password"
                      id="currentpassword"
                      className="form-control"
                      value={currentpassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary mt-2"
                    onClick={(e) => handleChangeEmail(e)}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        className="me-2"
                        size="sm"
                      />
                    ) : (
                      t("update_details")
                    )}
                  </button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
        {/* password Modal */}
        <Modal
          show={modalPassWordShow}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setModalPassWordShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="d-md-flex my-2">
                <div className="mb-3 mb-md-0">
                  <Image
                    src={item.icon}
                    alt=""
                    className=" bg-primary icon-shape icon-xxl rounded-circle"
                  />
                </div>
                {/*  Content  */}
                <div className="ms-md-4">
                  <h2 className="fw-bold mb-1">
                    {t(item.title)}
                    {/* <Badge bg="warning" className="ms-2">
										{item.badge}
									</Badge> */}
                  </h2>
                  <p className="text-uppercase fs-6 fw-semi-bold mb-0">
                    <span className="text-dark">
                      {t(item.shortdescription)}
                    </span>{" "}
                    {/* <span className="ms-3">{item.lessons} Lessons</span>{' '}
									<span className="ms-3">{item.duration}</span> */}
                  </p>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h4 className="mb-0">{t("change_password")}</h4>
              <p>{t("we_will_email")}</p>
              {/* Form */}
              <form>
                <div className="mb-3">
                  {/* Current password */}
                  <label htmlFor="currentpassword">
                    {t("current_password")}
                  </label>
                  <input
                    type="password"
                    id="currentpassword"
                    className="form-control"
                    value={currentpassword2}
                    onChange={(e) => setCurrentPassword2(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-3">
                  {/* New password */}
                  <label htmlFor="newpassword">{t("new_password")}</label>
                  <input
                    type="password"
                    id="newpassword"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>

                <Row className="align-items-center g-0">
                  <Col sm={6}>
                    <span
                      data-bs-toggle="tooltip"
                      data-placement="right"
                      title=""
                    >
                      {t("password_strength")}
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {t("test_if_by_typing")}
                          </Tooltip>
                        }
                      >
                        <i className="fas fa-question-circle ms-1"></i>
                      </OverlayTrigger>
                    </span>
                  </Col>
                </Row>
                <PasswordStrengthMeter password={password} />

                <div className="mb-3">
                  {/* Confirm new password */}
                  <label htmlFor="confirmpassword">
                    {t("confirm_new_password")}
                  </label>
                  <input
                    type="password"
                    id="confirmpassword"
                    className="form-control"
                    value={confirmpassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>

                {/* Button */}
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => handleChangePassword(e)}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      variant="light"
                      className="me-2"
                      size="sm"
                    />
                  ) : (
                    t("save_password")
                  )}
                </button>
                <div className="col-6"></div>
              </form>

              <div className="mt-4">
                <p className="mb-0">
                  {t("cant_remenber_your")}{" "}
                  <Link to="#">{t("reset_your_password")}</Link>
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export default CourseDescSettingCard;
