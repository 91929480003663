// import node module libraries
import { Fragment, useState, useRef, useEffect } from "react";
import { Form, Col, Row, Card } from "react-bootstrap";

// import full calendar and it's plugins
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

// import required data files

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { followSession } from "actions/sessions";
import FllowSession from "./FllowSession";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import session from "redux-persist/lib/storage/session";
//translate component
import { useTranslation } from "react-i18next";

const CalendarBook = ({ sessions, pastSessions }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Define required states
  const {
    current_session_followed,
    pending_sessions,
    followed_pack_list,
    followed_sessions,
  } = useSelector((state) => state.auth);

  const [checkPack, setCheckPack] = useState(null);

  useEffect(() => {
    if (sessions?.length > 0) {
      if (
        followed_pack_list?.filter((e) => e.id == sessions[0]?.pack_id).length >
        0
      )
        setCheckPack(
          followed_pack_list?.filter((e) => e.id == sessions[0]?.pack_id)[0]
            ?.approved
        );
    }
  }, [followed_pack_list.length]);
  console.log(checkPack);

  const calendarData = sessions
    ?.map((session) => {
      const extendedProps = session?.extendedProps || {
        category: "primary",
        description: "description",
        location: "classroom",
      }; // Ensure extendedProps is always an object

      if (current_session_followed?.find((e) => e.id === session.id)) {
        return {
          ...session,
          extendedProps: {
            ...extendedProps,
            category: "success",
          },
        };
      } else if (pending_sessions?.find((e) => e.id === session?.id)) {
        return {
          ...session,
          extendedProps: {
            ...extendedProps,
            category: "warning",
          },
        };
      } else if (
        followed_sessions?.find(
          (e) => e?.unit_id === session?.unit_id && e?.id === session?.id
        )
      ) {
        return {
          ...session,
          extendedProps: {
            ...extendedProps,
            category: "danger",
          },
        };
      } else {
        return session;
      }
    })
    ?.concat(
      pastSessions?.map((session) => {
        const extendedProps = session?.extendedProps || {
          category: "primary",
          description: "description",
          location: "classroom",
        };
        return {
          ...session,
          extendedProps: {
            ...extendedProps,
            category: "secondary",
          },
        };
      })
    );

  const [showEventOffcanvas, setShowEventOffcanvas] = useState(false);
  const [isEditEvent, setIsEditEvent] = useState(false);
  const [calendarApi, setCalendarApi] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDate, setselectedDate] = useState(0);
  const dates = sessions?.map((date) => date.start).sort((a, b) => a - b);

  // Methods / Functions
  const handleCloseEventOffcanvas = () => setShowEventOffcanvas(false);

  // Calendar Refs
  const calendarRef = useRef(null);

  // useEffect hook to check calendarApi Update
  useEffect(() => {
    if (calendarApi === null) {
      setCalendarApi(calendarRef?.current?.getApi());
    }
  }, [calendarApi]);
  const handleFollowSession = (e) => {
    e.preventDefault();

    dispatch(followSession(selectedEvent, navigate, handleCloseEventOffcanvas));
  };

  const handleChangeSession = (id) => {
    calendarApi.gotoDate(sessions.filter((s) => s.id == id)[0]?.start);
  };

  // Calendar Options or Properties
  const calendarOptions = {
    contentHeight: 500,
    ref: calendarRef,
    events: calendarData,
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    initialView: "listYear", // Keep it as listYear to show events per year
    headerToolbar: {
      left: "goToPrevButton dayGridMonth listYear goToNextButton",
      center: "",
      right: "",
    },
    editable: true,
    eventResizableFromStart: true,
    dayMaxEvents: 2,
    navLinks: true,
    eventClick({ event: clickedEvent }) {
      if (clickedEvent?._def?.extendedProps?.category === "primary") {
        setShowEventOffcanvas(true);
        setSelectedEvent(clickedEvent._def);
      }
    },

    eventClassNames({ event: calendarEvent }) {
      return [`text-white bg-${calendarEvent?.extendedProps?.category}`];
    },

    customButtons: {
      goToNextButton: {
        text: "Next Year >>",
        click: function () {
          const calendarApi = calendarRef?.current.getApi();
          calendarApi.nextYear(); // Moves the calendar to the next year
        },
      },
      goToPrevButton: {
        text: "<< Last Year",
        click: function () {
          const calendarApi = calendarRef?.current.getApi();
          calendarApi.prevYear(); // Moves the calendar to the previous year
        },
      },
    },
  };

  return (
    <Fragment>
      <ToastContainer />
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">{t("calendar")}</h1>
              {session.length > 0 && (
                <Form>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => handleChangeSession(e.target.value)}
                  >
                    {sessions.map((session) => (
                      <option key={session.id} value={session.id}>
                        {session.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form>
              )}
              {/* <Breadcrumb>
								<Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item active>Calendar</Breadcrumb.Item>
							</Breadcrumb> */}
            </div>
            <div>
              <FllowSession
                show={showEventOffcanvas}
                setShowEventOffcanvas={setShowEventOffcanvas}
                onHide={handleCloseEventOffcanvas}
                calendarApi={calendarApi}
                isEditEvent={isEditEvent}
                selectedEvent={selectedEvent}
                handleFollowSession={handleFollowSession}
                checkPack={checkPack}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={12} lg={12} md={12} xs={12}>
          <Card>
            {/* Calendar */}
            <FullCalendar {...calendarOptions} />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CalendarBook;
