// import node module libraries
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
//translate component
import { useTranslation } from "react-i18next";
// import layouts
import ScrollToTop from "layouts/dashboard/ScrollToTop";
import AllRoutes from "layouts/AllRoutes";
// import required stylesheet
import "simplebar/dist/simplebar.min.css";
import "tippy.js/animations/scale.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  authAction,
  fetchReaderData,
  getCokiesAction,
  getPackFollowed,
} from "actions/auth";
import { useSelector } from "react-redux";
import CookieConsent from "react-cookie-consent";
import Joyride from "react-joyride";
import { tourSteps } from "data/Tour";
import { ToastContainer } from "react-toastify";
function App() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { is_authenticated } = useSelector((state) => state.auth);
  const btnKey = "btn_i_agree";
  useEffect(() => {
    dispatch(getCokiesAction());
    dispatch(authAction());
    if (is_authenticated) {
      dispatch(fetchReaderData());
      dispatch(getPackFollowed());
    }
  }, [dispatch, is_authenticated]);

  return (
    <Router>
      {/* <Joyride
        steps={tourSteps}
        run={true}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        styles={{
          options: {
            overlayColor: 'rgba(0, 0, 0, 0.8)',
            primaryColor: '#754FFE'
          }
        }}


      /> */}
      <ScrollToTop />
      <AllRoutes />
      <CookieConsent
        location="bottom"
        buttonText={t(btnKey)}
        cookieName="IReadCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "white",
          fontSize: "15px",
          backgroundColor: "#6343D8",
          borderRadius: "3px",
        }}
        expires={150}
      >
        {t("cookie_text")}{" "}
        <Link to="/privacy-policy" target="_blank">
          {t("privacy_policy")}
        </Link>
        <ToastContainer />
      </CookieConsent>
    </Router>
  );
}

export default App;
