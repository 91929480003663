// import node module libraries
import { Fragment, useEffect, useState } from "react";

// import sub components
import HeroAcademy from "./HeroAcademy";
import WhatCustomersSay from "./WhatCustomersSay";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAccountAction, unFollowedBookAction } from "actions/auth";
import CalendarBook from "components/dashboard/calendar/CalendarBook";
import { fetchOneBook } from "actions/books";
import { Modal, Table } from "react-bootstrap";
//translate component
import { useTranslation } from "react-i18next";
import { Avatar } from "components/elements/bootstrap/Avatar";

const HomeAcademy = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const { book } = useSelector((state) => state.books);
  const handleClose = () => setShow(false);

  const { is_authenticated, unflollwedBooks } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (is_authenticated) dispatch(unFollowedBookAction());
  }, [dispatch, is_authenticated]);

  useEffect(() => {
    if (unflollwedBooks) {
      if (unflollwedBooks.length > 0) {
        if (unflollwedBooks[0].book_id !== undefined)
          dispatch(fetchOneBook(unflollwedBooks[0]?.book_id));
      }
    }
  }, [dispatch]);

  return (
    <Fragment>
      {/* {unflollwedBooks?.length > 0 &&
        unflollwedBooks[0]?.title !== undefined && (
          <Modal
            show={show && unflollwedBooks?.length > 0}
            onHide={handleClose}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("please_follow_on")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CalendarBook sessions={book?.sessions} />
            </Modal.Body>
          </Modal>
        )} */}

      {/* Hero Academy banner section */}
      <HeroAcademy />

      {/* Various acedamy statistics  */}
      {/* <AcademyStats /> */}

      {/* Most Popular Courses */}
      {/* <MostPopularCourses /> */}

      {/* Become an instructor */}
      {/* <BecomeAnInstructor /> */}

      {/* What our customers say */}
      <WhatCustomersSay />
    </Fragment>
  );
};
export default HomeAcademy;
