import {
  AUTH,
  GET_READER_DASHBOARD,
  LOADING,
  LOGOUT,
  GET_FOLLOWED_PACKS,
  CHANGE_EMAIL,
  FOLLOW_PACK,
  CHANGE_IMAGE,
  SET_PROFILE,
  GET_PROFILE,
  CLEAR_CURRENT,
  GET_UNFOLLOWED_BOOKS,
  FETCH_ACCOUNTS,
  CREATE_ACCOUNT,
  GET_ALL_GUIDE,
} from "./constants";
import {
  signUp,
  signIn,
  current,
  logOut,
  readerDashboard,
  getFollowedPackList,
  resendLink,
  changeEmail,
  changePassword,
  followPack,
  changeImage,
  setProfile,
  getProfile,
  getCokies,
  readerUnfollowedBooks,
  signInGoogle,
  selectAccount,
  createAccount,
  getAccounts,
  fetchAllGuide,
  createInvoice,
  createInvoiceForClient,
  createInvoiceClient,
} from "api";
import { toast } from "react-toastify";

export const signUpAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //signup the user
    const data = await signUp(formData);
   
    // await createInvoiceClient(formData);
    // console.log(data?.data?.user);

    dispatch({ type: LOADING, payload: false });
    localStorage.setItem("email", formData.email);
    window.location.href = "/authentication/mail-confirmation";
  } catch (error) {
    console.log(error);

    if (error?.response?.data?.message) {
      toast.error(error.response.data.message, { autoClose: 1000 });
    }
    dispatch({ type: LOADING, payload: false });
  }
};
export const signInAction = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data: signInData } = await signIn(formData);
    const { data } = await current();

    if (data && data.is_authenticated) {
      console.log("navigate");
      navigate("/reader/dashboard");
      dispatch({ type: AUTH, payload: data?.data });
      dispatch({ type: FETCH_ACCOUNTS, payload: signInData.accounts });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data);
      toast.error(error.response.data.message, { autoClose: 1000 });
    }
  }
};
export const selectAccountAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //login the user
    const { data: select } = await selectAccount(formData);
    const { data } = await current();
    const readerData = await readerDashboard();
    dispatch({ type: GET_READER_DASHBOARD, payload: readerData?.data });
    const followedPackList = await getFollowedPackList();
    dispatch({ type: GET_FOLLOWED_PACKS, payload: followedPackList.data });
    if (data?.is_authenticated) {
      localStorage.setItem("account", data);
      toast.success(select.message, { autoClose: 1000 });
      dispatch({ type: AUTH, payload: data });
    }
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message, { autoClose: 1000 });
    }
  }
};

export const createAccountAction =
  (formData, setNewAccont) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const { data } = await createAccount(formData);

      setNewAccont({
        username: "",
        password: "",
      });
      toast.success(data.message, { autoClose: 1000 });
      dispatch({ type: CREATE_ACCOUNT, payload: data.user });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      dispatch({ type: LOADING, payload: false });
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message, { autoClose: 1000 });
      }
    }
  };

export const signInGoogleAction = (formData, navigate) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //login the user
    const { data: signInData } = await signInGoogle(formData);
    const data = await current();

    if (data?.data?.is_authenticated) {
      dispatch({ type: AUTH, payload: data?.data });
      dispatch({ type: FETCH_ACCOUNTS, payload: signInData.accounts });
    }
    dispatch({ type: LOADING, payload: false });

    window.location.href = "/reader/dashboard";
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message, { autoClose: 1000 });
    }
  }
};

export const getAccountsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getAccounts();

    dispatch({ type: FETCH_ACCOUNTS, payload: data.accounts });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const authAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //current the user
    const data = await current();

    if (data?.data?.is_authenticated) {
      dispatch({ type: AUTH, payload: data?.data });
    } else {
      dispatch({ type: CLEAR_CURRENT });
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: CLEAR_CURRENT });
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const logOutAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //logout the user
    await logOut();

    dispatch({ type: LOGOUT });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const fetchReaderData = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //current the user
    const data = await readerDashboard();

    dispatch({ type: GET_READER_DASHBOARD, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const getPackFollowed = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //current the user
    const { data } = await getFollowedPackList();
    dispatch({ type: GET_FOLLOWED_PACKS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const resendLinkAction = (email) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    //login the user
    const data = await resendLink(email);

    dispatch({ type: LOADING, payload: false });
    toast.success(data?.data.message, {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
};

export const changeEmailAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await changeEmail(formData);
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch({ type: CHANGE_EMAIL, payload: data.user });
    dispatch({ type: LOADING, payload: false });
    return data.user;
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
};
export const changePasswordAction = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await changePassword(formData);
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    dispatch({ type: LOADING, payload: false });
    return data.message;
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
};
export const changeImageActions = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await changeImage(formData);
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    dispatch({ type: LOADING, payload: false });
    dispatch({ type: CHANGE_IMAGE, payload: formData.img });
    return data;
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
};

export const setProfileActions = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await setProfile(formData);
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    dispatch({ type: LOADING, payload: false });
    dispatch({ type: SET_PROFILE, payload: data.profile });
    return data;
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
};
export const getProfileActions = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getProfile(formData);
    toast.success(data.message, { autoClose: 1000 });

    dispatch({ type: LOADING, payload: false });
    dispatch({ type: GET_PROFILE, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error?.response?.data?.message);
    }
  }
};
export const followPackAction = (id, code, invoiceData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    // await createInvoice(invoiceData)
    const { data } = await followPack(id, code);

    toast.success(data.message, {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    if (data.fllowed_pack) {
      dispatch({ type: FOLLOW_PACK, payload: data.fllowed_pack });
      const { data: ReaderData } = await readerDashboard();
      dispatch({ type: GET_READER_DASHBOARD, payload: ReaderData });
    }
    window.location.href = "/student/dashboard";
    dispatch({ type: LOADING, payload: false });
    return data;
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
};

export const createInvoiceClientFollowPack =
  (id, code, invoiceData) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      await createInvoiceForClient();
      await createInvoice(invoiceData);
      const { data } = await followPack(id, code);

      toast.success(data.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      if (data.fllowed_pack) {
        dispatch({ type: FOLLOW_PACK, payload: data.fllowed_pack });
        const { data: ReaderData } = await readerDashboard();
        dispatch({ type: GET_READER_DASHBOARD, payload: ReaderData });
      }
      window.location.href = "/student/dashboard";
      dispatch({ type: LOADING, payload: false });
      return data;
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING, payload: false });
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
export const unFollowedBookAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await readerUnfollowedBooks();

    dispatch({
      type: GET_UNFOLLOWED_BOOKS,
      payload: data.unfollowed_books_list,
    });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
  }
};

export const getCokiesAction = () => async () => {
  try {
    await getCokies();
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllGuideAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchAllGuide();
    dispatch({ type: GET_ALL_GUIDE, payload: data.result });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
